import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/footer";
import Header from "../component/header";
import Space from "../component/space";
import APIRequest, { aboutInfo } from "../services/api";
interface Community {
    name: String
}
const About = () => {
const localWallet: any = localStorage.getItem('wallet');
const [community,setCommunity]= useState<any>({});
const [events,setEvents]= useState<any>({});
    useEffect(() => {
        (async () => {
            const wallet = JSON.parse(localWallet).wallet;
            const aboutData = await APIRequest('').get(aboutInfo+wallet);
            setCommunity(aboutData.data.data.community);
            setEvents(aboutData.data.data.events);
            console.log(aboutData.data.data.events);
        })();
      
        return () => {
          // this now gets called when the component unmounts
        };
      }, []);
return (
<div id="layout-wrapper">
    <Header />
    <div className="main-content">
        <div className="page-content">
            <div className="container-fluid">
                <div className="profile-foreground position-relative mx-n4 mt-n4">
                    <div className="profile-wid-bg">
                        <img src="/assets/media/profile-bg.jpg" alt="" className="profile-wid-img" />
                    </div>
                </div>
                <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
                    <div className="row g-4">
                        <div className="col-auto">
                            <div className="avatar-lg">
                                <img src={community.profile_pic} alt="user-img"
                                    className="img-thumbnail rounded-circle" />
                            </div>
                        </div>

                        <div className="col">
                            <div className="p-2">
                                <h3 className="text-white mb-1">{community?.name}</h3>
                                <p className="text-white-75">{community.position}</p>
                                <div className="hstack text-white-50 gap-1">
                                   
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div>
                            <div className="d-flex">

                                <ul className="nav nav-pills animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
                                    role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link fs-14 active" data-bs-toggle="tab" href="#overview-tab"
                                            role="tab" aria-selected="true">
                                            <i className="ri-airplay-fill d-inline-block d-md-none"></i> <span
                                                className="d-none d-md-inline-block">Overview</span>
                                        </a>
                                    </li>
                                </ul>
                                <div className="flex-shrink-0">
                                    <a href="#" className="btn btn-success"><i
                                            className="ri-edit-box-line align-bottom"></i> Edit Profile</a>
                                </div>
                            </div>

                            <div className="tab-content pt-4 text-muted">
                                <div className="tab-pane active" id="overview-tab" role="tabpanel">
                                    <div className="row">

                                        <div className="col-xxl-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title mb-3">About</h5>
                                                    <p>{community.bio}</p>
                                                </div>
                                            

                                            </div>
                                        </div>

                                    </div>
                               
                                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-lg-flex align-items-center mb-4">
                                <div className="flex-grow-1">
                                    <h5 className="card-title mb-0 fw-bold fs-17">Events</h5>
                                </div>
                                <div className="flex-shrink-0 mt-4 mt-lg-0">
                                    <a href="#" className="btn btn-soft-primary">View All <i className="ri-arrow-right-line align-bottom"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row row-cols-xl-5 row-cols-lg-3 row-cols-md-2 row-cols-1">
                        {  Object.entries(events).map(([key, value]) => (
                            
                        <div className="col">
                          <div className="card explore-box">
                              <div className="card-body">
                                  <div className="explore-place-bid-img overflow-hidden rounded">
                                      <img src={events[key].listing_image} alt="" height="170" className="img-fluid explore-img  height170"/>
                                  </div>
                                  <div className="mt-3">
                                  
                                      <h6 className="fs-16 mb-0"><a href="" className="link-dark">{events[key].event_name}</a></h6>
                                  </div>
                              </div>
                          </div>
                      </div>  
                        ))}
                        
                        
                      
                        
                    </div>
                                   
                                </div>
                            </div>

                        </div>
                    </div>

                </div>


            </div>
            <Footer />
        </div>
    </div>
</div>
);

}

export default About;