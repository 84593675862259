import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../component/footer";
import Header from "../../component/header";
import Space from "../../component/space";
import APIRequest, { eCreate } from "../../services/api";
const AddEvents = () => {
    const navigate = useNavigate();
    const saveEvent = async (event:any) => {
        event.preventDefault();
        const payload = new FormData();
        const CID:any = localStorage.getItem('cId');
        payload.append("community_id", CID);
        payload.append("event_type_id", "1");
        
        payload.append("event_name", name);
        payload.append("description", description);
        payload.append("thank_you", thankYou);
        payload.append("end_date", ends);
        
        payload.append("listing_image",listingImage);
        payload.append("cover_image", coverImage);

        payload.append("topic_image_1", topicImage_1);
        payload.append("topic_image_2", topicImage_2);
        payload.append("topic_image_3", topicImage_3);
        payload.append("topic_image_4", topicImage_4);
        payload.append("topic_image_5", topicImage_5);

        payload.append("cause_1", cause_1);
        payload.append("cause_2", cause_2);
        payload.append("cause_3", cause_3);
        payload.append("cause_4", cause_4);
        payload.append("cause_5", cause_5);
        
        console.log(payload);

        const onSuccess = ({data}:any) => {
            console.log(data);
            if(data.suc){
                navigate('/panel/about');
            }else{
                setMessage(data.msg);
            }
        }
        const onFailure = (error:any) => {
            console.log(error)
        }
        await APIRequest('').post(eCreate, payload).then(onSuccess).catch(onFailure);;
    }
    const file: File = {
        lastModified: 0,
        name: "",
        webkitRelativePath: "",
        size: 0,
        type: "",
        arrayBuffer: function (): Promise<ArrayBuffer> {
            throw new Error("Function not implemented.");
        },
        slice: function (start?: number | undefined, end?: number | undefined, contentType?: string | undefined): Blob {
            throw new Error("Function not implemented.");
        },
        stream: function (): ReadableStream<Uint8Array> {
            throw new Error("Function not implemented.");
        },
        text: function (): Promise<string> {
            throw new Error("Function not implemented.");
        }
    };
    const [message,setMessage]=useState('');
    const [name,setName]=useState('');
    const [listingImage,setListingImage]=useState(file);
    const [coverImage,setCoverImage]=useState(file);
    
    const [topicImage_1,setTopicImage_1]=useState(file);
    const [topicImage_2,setTopicImage_2]=useState(file);
    const [topicImage_3,setTopicImage_3]=useState(file);
    const [topicImage_4,setTopicImage_4]=useState(file);
    const [topicImage_5,setTopicImage_5]=useState(file);
    
    const [description,setDescription]=useState('');
    const [thankYou,setThankYou]=useState('');
    const [cause_1,setCause_1]=useState('');
    const [cause_2,setCause_2]=useState('');
    const [cause_3,setCause_3]=useState('');
    const [cause_4,setCause_4]=useState('');
    const [cause_5,setCause_5]=useState('');

    const [ends,setEnds]=useState('2023-01-22');
    const ImageList_1 = (event:any) => {
        setTopicImage_1(event.target.files[0]);
   }
   const ImageList_2 = (event:any) => {
    setTopicImage_2(event.target.files[0]);
}
const ImageList_3 = (event:any) => {
    setTopicImage_3(event.target.files[0]);
}
const ImageList_4 = (event:any) => {
    setTopicImage_4(event.target.files[0]);
}
const ImageList_5 = (event:any) => {
    setTopicImage_5(event.target.files[0]);
}


    const listingImageHandle = (event:any) => {
        setListingImage(event.target.files[0]);
   }
   const coverPicHandle = (event:any) => {
    setCoverImage(event.target.files[0]);
}

    return (
        <div id="layout-wrapper">
            <Header />
            <div className="main-content">
                <div className="page-content">
                <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                <div className="card rounded-0 bg-soft-success mx-n4 mt-n4 border-top">
                <div className="px-4">
                    <div className="row">
                        <div className="col-xxl-5 align-self-center">
                            <div className="py-4">
                                <h4>Add New Events</h4>
                                <p className="text-success fs-16 mt-3">Add a new event for rate your opinion event template.</p>
                            </div>
                        </div>
                    </div>
                        </div>
                    </div>
                    </div>
                </div>

            
                <div className="row">
                    <div className="col-xxl-12">
                        <div className="card p-5">
                            <h4>Performance Score Template</h4>
                            <form action="" encType="multipart/form-data" onSubmit={(event)=>saveEvent(event)}>
                                <div className="row">
                                <div className="form-group col-md-4">
                                    <label>Name of event</label>
                                    <input type="text" className="form-control" name="name" value={name} onChange={event => {setName(event.target.value)}} />
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Listing Image</label>
                                    <input type="file" className="form-control" name="listing_image"  onChange={(event)=>listingImageHandle(event)}/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Cover Image</label>
                                    <input type="file" className="form-control" name="cover_pic" onChange={(event)=>coverPicHandle(event)} />
                                </div>
                                </div>
                                <div className="form-group col-md-12">
                                    <label>Event Description</label>
                                    <textarea rows={3} className="form-control" name="description" value={description} onChange={event => {setDescription(event.target.value)}} />
                                </div>
                                <div className="form-group col-md-12">
                                    <label>Thank you message</label>
                                    <textarea rows={2} className="form-control" name="thankyou" value={thankYou} onChange={event => {setThankYou(event.target.value)}} />
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Event ends on</label>
                                    <input type="text" placeholder="2023-01-23" className="form-control" name="enddate" value={ends} onChange={event => {setEnds(event.target.value)}}  />
                                </div>

                                <h4 className="py-3">Tabs for the event</h4>
                                <div className="row pb-3">
                                    <div className="form-group col-md-4">
                                        <label>Name</label>
                                        <input type="text" className="form-control" name="cause[]"  value={cause_1} onChange={event => {setCause_1(event.target.value)}} />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>Listing Image</label>
                                        <input type="file" className="form-control" name="topic_image_1" onChange={(event)=>ImageList_1(event)} />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className="form-group col-md-4">
                                        <label>Name</label>
                                        <input type="text" className="form-control" name="cause[]" value={cause_2} onChange={event => {setCause_2(event.target.value)}} />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>Listing Image</label>
                                        <input type="file" className="form-control" name="topic_image_2" onChange={(event)=>ImageList_2(event)}  />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className="form-group col-md-4">
                                        <label>Name</label>
                                        <input type="text" className="form-control" name="cause[]"  value={cause_3} onChange={event => {setCause_3(event.target.value)}}/>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>Listing Image</label>
                                        <input type="file" className="form-control" name="topic_image_3" onChange={(event)=>ImageList_3(event)}  />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className="form-group col-md-4">
                                        <label>Name</label>
                                        <input type="text" className="form-control" name="cause[]"  value={cause_4} onChange={event => {setCause_4(event.target.value)}}/>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>Listing Image</label>
                                        <input type="file" className="form-control" name="topic_image_4"  onChange={(event)=>ImageList_4(event)} />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className="form-group col-md-4">
                                        <label>Name</label>
                                        <input type="text" className="form-control" name="cause[]"  value={cause_5} onChange={event => {setCause_5(event.target.value)}}/>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>Listing Image</label>
                                        <input type="file" className="form-control" name="topic_image_5"  onChange={(event)=>ImageList_5(event)} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <input type="submit" className="btn btn-success" name="" value={'Save Event'}/>
                                    </div>
                                </div>
                            </form>
                        </div>
                        
                    </div>
                    
                    
                </div>
                

            


                
            </div>
                    <Footer />
                </div>
            </div>
        </div>
    );

}
export default AddEvents;