import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../component/footer";
import Header from "../../component/header";
export default class EditEvents extends React.Component {
    render() {
        return (
            
            <div id="layout-wrapper">
            <Header />
            <div className="main-content">
                <div className="page-content">
                <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                <div className="card rounded-0 bg-soft-success mx-n4 mt-n4 border-top">
                <div className="px-4">
                    <div className="row">
                        <div className="col-xxl-5 align-self-center">
                            <div className="py-4">
                                <h4>Edit Event</h4>
                                <p className="text-success fs-16 mt-3">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                            </div>
                        </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>

                


                <div className="row">
	<div className="col-lg-8">
		<div className="card">
			<div className="card-body">
				<div className="mb-3">
					<label className="form-label">Name of Event</label>
					<input
						type="text"
						className="form-control"
						id="project-title-input"
						placeholder="Name of Event"
					/>
				</div>

				<div className="mb-3">
					<label className="form-label">Thumbnail Image</label>
					<input
						className="form-control"
						id="project-thumbnail-img"
						type="file"
						accept="image/png, image/gif, image/jpeg"
					/>
				</div>

				<div className="mb-3">
					<label className="form-label">Project Description</label>
					<div id="ckeditor-classic" style={{ display: "none" }}>
						<p>
							It will be as simple as occidental in fact, it will
							be Occidental. To an English person, it will seem
							like simplified English, as a skeptical Cambridge
							friend of mine told me what Occidental is. The
							European languages are members of the same family.
							Their separate existence is a myth. For science,
							music, sport, etc, Europe uses the same vocabulary.
						</p>
						<ul>
							<li>Product Design, Figma (Software), Prototype</li>
							<li>
								Four Dashboards : Ecommerce, Analytics, Project
								etc.
							</li>
							<li>Create calendar, chat and email app pages.</li>
							<li>Add authentication pages</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div className="card">
			<div className="card-header">
				<h5 className="card-title mb-0">Attached files</h5>
			</div>
			<div className="card-body">
				<div>
					<p className="text-muted">Add Attached files here.</p>

					<div className="dropzone dz-clickable">
						<div className="dz-message needsclick">
							<div className="mb-3">
								<i className="display-4 text-muted ri-upload-cloud-2-fill"></i>
							</div>

							<h5>Drop files here or click to upload.</h5>
						</div>
					</div>

					<ul
						className="list-unstyled mb-0"
						id="dropzone-preview"
					></ul>
				</div>
			</div>
		</div>

		<div className="text-end mb-4">
			<button type="submit" className="btn btn-danger w-sm">
				Delete
			</button>
			<button type="submit" className="btn btn-secondary w-sm">
				Draft
			</button>
			<button type="submit" className="btn btn-success w-sm">
				Create
			</button>
		</div>
	</div>
</div>


            


              
            </div>
                    
                </div>
                <Footer />
            </div>
        </div>
                    
        );
    }
}
