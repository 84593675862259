import React, { useEffect, useState } from 'react';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { useAccount, useConnect } from 'wagmi'

import { useNavigate } from "react-router-dom";
import APIRequest, { communityCheck } from '../services/api';
import '../styles/index.css';
import LoginLayout from './layout/LoginLayout';



// import logo from './public/assets/media/logo.png';
 const Home = () =>  {
    const { connect, connectors, error, isLoading, pendingConnector } = useConnect();
    const { connector: activeConnector, isConnected } = useAccount()
    const { address } = useAccount()
    useEffect(()=>{
        // if(isConnected && address){
        //     // navigate('/panel/dashboard');
        // }
        createCommunityItem();
        if(localStorage.getItem('walet')){
             navigate('/panel/dashboard');
        }
    })
    // const [loading,setLoading]=useState(false);
    const navigate = useNavigate();
    const connector = new MetaMaskConnector({
        options: {
          shimDisconnect: true,
        },
      })
    // const { address } = useAccount()
   
    const createCommunityItem = async () => {
        const accountInfo = JSON.stringify({'wallet':address});
        const onSuccess = ({data}:any) => {
            localStorage.setItem('wallet',accountInfo);
            console.log(data.data);
            if(data.data.community){
                navigate('/panel/dashboard');
            }else{
                navigate('/community/create');
            }
            // setLoading(false);
        }

        const onFailure = (error:any) => {
            console.log(error);
            // setLoading(false);
        }
        if(isConnected){
            const payload = {wallet:address};
            await APIRequest('').post(communityCheck, payload).then(onSuccess).catch(onFailure);;
        }
    }

    const handleAuth = async () => {
        if(!isConnected) connect({ connector });

        createCommunityItem();
        
    };

        return (
            <LoginLayout>
                <div className=" center">
                                <div className="website-logo-inside">
                                    <a href="/">
                                        <div className="logo">
                                            <img className="logo-size" src={`${process.env.PUBLIC_URL}/assets/media/logo.png`} alt=""/>
                                        </div>
                                    </a>
                                </div>
                                <div className="center">
                                <h3 style={{color:'#000'}}>mitoken.tech</h3>
                                            <p>Create your own community with 100% geneiune members....</p>
                                </div>
                                <div className="page-links">
                                    <button className="btn btn-primary px-5 rounded-0"  type='button'  onClick={() => handleAuth()}>COMMUNITY LOGIN</button>
                                </div>
                            </div>
                            </LoginLayout>
        );
    }

export default Home;