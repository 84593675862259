import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/footer";
import Header from "../component/header";
import panelLayout from "../component/panelLayout";
import Sidebar from "../component/sidebar";
import Space from "../component/space";
import APIRequest, { aboutInfo } from "../services/api";
import Chart from "react-apexcharts";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';
  
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Transactions & Users',
      },
    },
  };
  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

  export const data = {
    labels,
    datasets: [
      {
        label: 'Users',
        data: labels.map(() => [1,2,4]),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Transactions',
        data: labels.map(() => [1,23,43]),
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
      {
        label: 'Votes',
        data: labels.map(() => [1,24,83]),
        backgroundColor: 'red',
      },
      {
        label: 'Tokens',
        data: labels.map(() => [1,42,12]),
        backgroundColor: 'green',
      },
    ],
  };

const Dashboard = () =>  {
    
    const option1 =  {
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
        }
      };
      const series1 =  [
        {
          name: "Users",
          data: [30, 40, 45, 50, 49, 60, 70, 91]
        },
        {
            name: "Transactions",
            data: [30, 33, 33, 45, 1, 123, 12, 30]
        },
        {
            name: "Followers",
            data: [2, 4, 10, 12, 19, 25, 28, 55]
        }
      ];

    const localWallet: any = localStorage.getItem('wallet');
    useEffect(() => {
        (async () => {
            const wallet = JSON.parse(localWallet).wallet;
            const aboutData = await APIRequest('').get(aboutInfo+wallet);
            localStorage.setItem('cId',aboutData.data.data.community.id);;
        })();
      
        return () => {
          // this now gets called when the component unmounts
        };
      }, []);
    return (
        <div id="layout-wrapper">
            <Header/>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                    <div className="row">
                            <div className="col-xl-3 col-md-6">
                                <div className="card card-animate">
                                    <div className="card-body bg-danger">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <p className="fw-semibold text-white mb-0">Followers</p>
                                                <h2 className="mt-4 text-white fw-bold"><span className="counter-value" data-target="28.05">0</span>k</h2>
                                            </div>
                                            <div>
                                                <div className="avatar-sm flex-shrink-0">
                                                    <span className="avatar-title bg-white rounded-circle fs-2">
                                                        <i className="text-info ri-account-circle-fill"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div> 

                            <div className="col-xl-3 col-md-6">
                                <div className="card card-animate">
                                    <div className="card-body bg-dark">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <p className="fw-semibold text-white mb-0">Shares</p>
                                                <h2 className="mt-4 ff-secondary text-white fw-bold"><span className="counter-value" data-target="0">0</span>k</h2>
                                            </div>
                                            <div>
                                                <div className="avatar-sm flex-shrink-0">
                                                    <span className="avatar-title bg-white rounded-circle fs-2">
                                                        <i  className="text-info ri-bar-chart-fill"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            

                            <div className="col-xl-3 col-md-6">
                                <div className="card card-animate bg-primary">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <p className="fw-semibold text-white-50 mb-0">Fans</p>
                                                <h2 className="mt-4 ff-secondary fw-bold text-white">
                                                    <span className="counter-value"
                                                    data-target="3">0</span><span className="counter-value"
                                                        data-target="40"></span>k</h2>
                                                
                                            </div>
                                            <div>
                                                <div className="avatar-sm flex-shrink-0">
                                                    <span className="avatar-title bg-soft-light rounded-circle fs-2">
                                                        <i className="ri-money-dollar-circle-line text-white"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            

                            <div className="col-xl-3 col-md-6">
                                <div className="card card-animate">
                                    <div className="card-body bg-info">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <p className="fw-semibold text-white mb-0">Reach</p>
                                                <h2 className="mt-4 ff-secondary text-white fw-bold"><span className="counter-value"
                                                    data-target="33.48">0</span>k</h2>
                                                
                                            </div>
                                            <div>
                                                <div className="avatar-sm flex-shrink-0">
                                                    <span className="avatar-title bg-white rounded-circle fs-2">
                                                        <i className="ri-funds-line text-info"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            
                        </div>
                        <div className="row">
                        <div className="col-xxl-3">
                                
                            
                        <div className="card">
                                <div className="card-body p-0">
                                    <div className="alert alert-warning border-0 rounded-top rounded-0 m-0 d-flex align-items-center" role="alert">
                                        <div className="flex-grow-1 text-truncate">
                                           Last Update <b>3 hours</b> ago
                                        </div>
                                       
                                    </div>
                                    <div className="d-flex px-3">
                                        <div>
                                            <h2 className="mt-4 text-secondary fw-bold">5,000 </h2>
                                            <p className="fs-16 lh-base">Total Number of Users</p>
                                            <h2 className="mt-4 text-danger fw-bold">15,000 NFT </h2>
                                            <p className="fs-16 lh-base">Total Number of NFT Transaction</p>
                                        </div>
                                    </div>
                                </div> 
                            </div>






                            </div>
                            <div className="col-xxl-9">
                            
                                <div className="card card-height-100">
                                    <div className="card-header align-items-center d-flex">
                                        <h4 className="card-title mb-0 flex-grow-1">Users / Transactions / Followers</h4>
                                        <div className="flex-shrink-0">
                                            <div className="dropdown card-header-dropdown">
                                                <a className="text-reset dropdown-btn" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span className="fw-bold text-uppercase fs-12">Sort by: </span><span className="text-muted">Current Year<i className="mdi mdi-chevron-down ms-1"></i></span>
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-end">
                                                    <a className="dropdown-item" href="#">Today</a>
                                                    <a className="dropdown-item" href="#">Last Week</a>
                                                    <a className="dropdown-item" href="#">Last Month</a>
                                                    <a className="dropdown-item" href="#">Current Year</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body px-0">
                                        <Chart
              options={option1}
              series={series1}
              type="line"
              height={300}
            />
            
                                    </div>
                                </div>
                        


                            </div>
                            
                        </div>
                     
                        
                    </div>
                    <Footer/>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;