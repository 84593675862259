import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import APIRequest , {cCreate, walletIDToUserID} from "../services/api";
import '../styles/index.css';
import LoginLayout from "./layout/LoginLayout";

const Community = () => {
    const navigate = useNavigate();
    const [userID,setUserID]=useState('');
    useEffect(() => {
        (async () => {
            
            const onSuccess = ({data}:any) => {

                setUserID(data.data.id);
            }
    
            const onFailure = (error:any) => {
                console.log(error);
            }
            let payload =  {walletID: localStorage.getItem('wallet')}
            await APIRequest('').post(walletIDToUserID, payload).then(onSuccess).catch(onFailure);;

        })();
      
        return () => {
          // this now gets called when the component unmounts
        };
      }, []);

    const file: File = {
        lastModified: 0,
        name: "",
        webkitRelativePath: "",
        size: 0,
        type: "",
        arrayBuffer: function (): Promise<ArrayBuffer> {
            throw new Error("Function not implemented.");
        },
        slice: function (start?: number | undefined, end?: number | undefined, contentType?: string | undefined): Blob {
            throw new Error("Function not implemented.");
        },
        stream: function (): ReadableStream<Uint8Array> {
            throw new Error("Function not implemented.");
        },
        text: function (): Promise<string> {
            throw new Error("Function not implemented.");
        }
    };
    const [name,setName]=useState('');
    const [bio,setBio]=useState('');
    const [position,setPosition]=useState('');
    const [listingImage,setListingImage]=useState(file);
    const [coverImage,setCoverImage]=useState(file);
    const [profileImage,setProfileImage]=useState(file);
    const [message,setMessage]=useState('');
    
    const listingImageHandle = (event:any) => {
         setListingImage(event.target.files[0]);
    }
    const profilePicHandle = (event:any) => {
		setProfileImage(event.target.files[0]);
    }
    const coverPicHandle = (event:any) => {

		 setCoverImage(event.target.files[0]);
    }

    const createCommunity = async (event:any) => {
        event.preventDefault();
        console.log(listingImage);
        if(!name || !bio || !position){
            setMessage('Required field is missing');
            return ;
        }
        const payload = new FormData();
        payload.append("name", name);
        payload.append("bio", bio);
        payload.append("user_id", userID);
        payload.append("position", position);
        
        payload.append("listing_image",listingImage);
        payload.append("cover_pic", coverImage);
        payload.append("profile_pic", profileImage);


        const onSuccess = ({data}:any) => {
            console.log(data);
            if(data.suc){
                navigate('/panel/about');
            }else{
                setMessage(data.msg);
            }
            
        }
        const onFailure = (error:any) => {
            console.log(error)
        }
        await APIRequest('').post(cCreate, payload).then(onSuccess).catch(onFailure);;
    }
    return (
        <LoginLayout>
    <form className="p-lg-5 px-4" onSubmit={createCommunity} encType="multipart/form-data">

        <img src={`${process.env.PUBLIC_URL}/assets/media/logo.png`} width="80" alt="" />
        {message ? (
            <div className="alert alert-danger"> {message}</div>
        ): ''}
        <div className="form-group">
            <label>Name of the community</label>
            <input type="text" name="name" className="form-control" required value={name} onChange={event => {setName(event.target.value)}}/>
        </div>
        <div className="form-group pb-2">
            <label>Community Info</label>
            <textarea rows={3} name="bio" className="form-control" required value={bio} onChange={event => {setBio(event.target.value)}}/>
        </div>
        <div className="row pb-2">
        <div className="form-group col-md-6">
            <label>Your Designation</label>
            <input type="text" name="position" className="form-control" required value={position} onChange={event => {setPosition(event.target.value)}}/>
        </div>
        <div className="form-group col-md-6">
            <label>Listing Picture</label>
            <input type="file" name="listing_image" className="form-control" required onChange={(event)=>listingImageHandle(event)}/>
        </div>
        </div>
        <div className="row">
            <div className="form-group col-md-6">
                <label>Profile Picture</label>
                <input type="file" name="profile_pic" className="form-control" required onChange={(event)=>profilePicHandle(event)}/>
            </div>
            <div className="form-group col-md-6">
                <label>Cover Picture</label>
                <input type="file" name="cover_pic" className="form-control" required onChange={(event)=>coverPicHandle(event)}/>
            </div>
        </div>

        <div className="mt-4">
            <button className="btn btn-primary px-5 rounded-0" type="submit">Create Community</button>
        </div>
    </form>
</LoginLayout>
    );
}

export default Community;