import React from "react";
import { Link } from "react-router-dom";
export default class Footer extends React.Component {
    render() {
        return (
            <footer className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        © Copyright 2022 | MiToken Technology Limited | Sheffield Technology Park, Cooper Building, Arundel St. Sheffield - S12NS, United Kingdom
                    </div>
                </div>
            </div>
        </footer>
        );
    }
}
