import React from "react";
import { Link } from "react-router-dom";
import Footer from "../component/footer";
import Header from "../component/header";
import panelLayout from "../component/panelLayout";
import Sidebar from "../component/sidebar";
import Space from "../component/space";
export default class NFT extends React.Component {
    render() {
        return (
            <div id="layout-wrapper">
                <Header/>
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                        <div className="row">
                        <div className="col-lg-12">
                            <div className="d-lg-flex align-items-center mb-4">
                                <div className="flex-grow-1">
                                    <h5 className="card-title mb-0 fw-bold fs-17">Recent NFTs Artwork</h5>
                                </div>
                                <div className="flex-shrink-0 mt-4 mt-lg-0">
                                    <a href="apps-nft-creators.html" className="btn btn-soft-primary">View All <i className="ri-arrow-right-line align-bottom"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className="row row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-1">
                        
                        <div className="col">
                            <div className="card explore-box card-animate">
                                <div className="bookmark-icon position-absolute top-0 end-0 p-2">
                                    <button type="button" className="btn btn-icon active" data-bs-toggle="button" aria-pressed="true"><i className="mdi mdi-cards-heart fs-16"></i></button>
                                </div>
                                <div className="explore-place-bid-img">
                                    <img src="https://img.themesbrand.com/velzon/images/img-2.gif" alt="" className="card-img-top explore-img"/>
                                    <div className="bg-overlay"></div>
                                    <div className="place-bid-btn">
                                        <a href="#!" className="btn btn-success"><i className="ri-auction-fill align-bottom me-1"></i> Place Bid</a>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <p className="fw-medium mb-0 float-end"><i className="mdi mdi-heart text-danger align-middle"></i> 94.1k </p>
                                    <h5 className="mb-1"><a href="apps-nft-item-details.html" className="link-dark">Trendy Fashion Portraits</a></h5>
                                    <p className="text-muted mb-0">3d Style</p>
                                </div>
                                <div className="card-footer border-top border-top-dashed">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1 fs-14">
                                            <i className="ri-price-tag-3-fill text-warning align-bottom me-1"></i> Published <span className="fw-medium">1 day ago</span>
                                        </div>
                                        <h5 className="flex-shrink-0 fs-14 text-primary mb-0">0.56 MIT</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col">
                            <div className="card explore-box card-animate">
                                <div className="bookmark-icon position-absolute top-0 end-0 p-2">
                                    <button type="button" className="btn btn-icon active" data-bs-toggle="button" aria-pressed="true"><i className="mdi mdi-cards-heart fs-16"></i></button>
                                </div>
                                <div className="explore-place-bid-img">
                                    <img src="https://img.themesbrand.com/velzon/images/img-4.gif" alt="" className="card-img-top explore-img"/>
                                    <div className="bg-overlay"></div>
                                    <div className="place-bid-btn">
                                        <a href="#!" className="btn btn-success"><i className="ri-auction-fill align-bottom me-1"></i> Place Bid</a>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <p className="fw-medium mb-0 float-end"><i className="mdi mdi-heart text-danger align-middle"></i> 15.93k </p>
                                    <h5 className="mb-1"><a href="apps-nft-item-details.html" className="link-dark">Evolved Reality</a></h5>
                                    <p className="text-muted mb-0">Video</p>
                                </div>
                                <div className="card-footer border-top border-top-dashed">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1 fs-14">
                                            <i className="ri-price-tag-3-fill text-warning align-bottom me-1"></i> Published <span className="fw-medium">2 days ago</span>
                                        </div>
                                        <h5 className="flex-shrink-0 fs-14 text-primary mb-0">0.5 MIT</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card explore-box card-animate">
                                <div className="bookmark-icon position-absolute top-0 end-0 p-2">
                                    <button type="button" className="btn btn-icon active" data-bs-toggle="button" aria-pressed="true"><i className="mdi mdi-cards-heart fs-16"></i></button>
                                </div>
                                <div className="explore-place-bid-img">
                                    <img src="https://img.themesbrand.com/velzon/images/img-2.gif" alt="" className="card-img-top explore-img"/>
                                    <div className="bg-overlay"></div>
                                    <div className="place-bid-btn">
                                        <a href="#!" className="btn btn-success"><i className="ri-auction-fill align-bottom me-1"></i> Place Bid</a>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <p className="fw-medium mb-0 float-end"><i className="mdi mdi-heart text-danger align-middle"></i> 94.1k </p>
                                    <h5 className="mb-1"><a href="apps-nft-item-details.html" className="link-dark">Trendy Fashion Portraits</a></h5>
                                    <p className="text-muted mb-0">3d Style</p>
                                </div>
                                <div className="card-footer border-top border-top-dashed">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1 fs-14">
                                            <i className="ri-price-tag-3-fill text-warning align-bottom me-1"></i> Published <span className="fw-medium">1 day ago</span>
                                        </div>
                                        <h5 className="flex-shrink-0 fs-14 text-primary mb-0">0.56 MIT</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col">
                            <div className="card explore-box card-animate">
                                <div className="bookmark-icon position-absolute top-0 end-0 p-2">
                                    <button type="button" className="btn btn-icon active" data-bs-toggle="button" aria-pressed="true"><i className="mdi mdi-cards-heart fs-16"></i></button>
                                </div>
                                <div className="explore-place-bid-img">
                                    <img src="https://img.themesbrand.com/velzon/images/img-4.gif" alt="" className="card-img-top explore-img"/>
                                    <div className="bg-overlay"></div>
                                    <div className="place-bid-btn">
                                        <a href="#!" className="btn btn-success"><i className="ri-auction-fill align-bottom me-1"></i> Place Bid</a>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <p className="fw-medium mb-0 float-end"><i className="mdi mdi-heart text-danger align-middle"></i> 15.93k </p>
                                    <h5 className="mb-1"><a href="apps-nft-item-details.html" className="link-dark">Evolved Reality</a></h5>
                                    <p className="text-muted mb-0">Video</p>
                                </div>
                                <div className="card-footer border-top border-top-dashed">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1 fs-14">
                                            <i className="ri-price-tag-3-fill text-warning align-bottom me-1"></i> Published <span className="fw-medium">2 days ago</span>
                                        </div>
                                        <h5 className="flex-shrink-0 fs-14 text-primary mb-0">0.5 MIT</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col">
                            <div className="card explore-box card-animate">
                                <div className="bookmark-icon position-absolute top-0 end-0 p-2">
                                    <button type="button" className="btn btn-icon active" data-bs-toggle="button" aria-pressed="true"><i className="mdi mdi-cards-heart fs-16"></i></button>
                                </div>
                                <div className="explore-place-bid-img">
                                    <img src="https://img.themesbrand.com/velzon/images/img-2.gif" alt="" className="card-img-top explore-img"/>
                                    <div className="bg-overlay"></div>
                                    <div className="place-bid-btn">
                                        <a href="#!" className="btn btn-success"><i className="ri-auction-fill align-bottom me-1"></i> Place Bid</a>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <p className="fw-medium mb-0 float-end"><i className="mdi mdi-heart text-danger align-middle"></i> 94.1k </p>
                                    <h5 className="mb-1"><a href="apps-nft-item-details.html" className="link-dark">Trendy Fashion Portraits</a></h5>
                                    <p className="text-muted mb-0">3d Style</p>
                                </div>
                                <div className="card-footer border-top border-top-dashed">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1 fs-14">
                                            <i className="ri-price-tag-3-fill text-warning align-bottom me-1"></i> Published <span className="fw-medium">1 day ago</span>
                                        </div>
                                        <h5 className="flex-shrink-0 fs-14 text-primary mb-0">0.56 MIT</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col">
                            <div className="card explore-box card-animate">
                                <div className="bookmark-icon position-absolute top-0 end-0 p-2">
                                    <button type="button" className="btn btn-icon active" data-bs-toggle="button" aria-pressed="true"><i className="mdi mdi-cards-heart fs-16"></i></button>
                                </div>
                                <div className="explore-place-bid-img">
                                    <img src="https://img.themesbrand.com/velzon/images/img-4.gif" alt="" className="card-img-top explore-img"/>
                                    <div className="bg-overlay"></div>
                                    <div className="place-bid-btn">
                                        <a href="#!" className="btn btn-success"><i className="ri-auction-fill align-bottom me-1"></i> Place Bid</a>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <p className="fw-medium mb-0 float-end"><i className="mdi mdi-heart text-danger align-middle"></i> 15.93k </p>
                                    <h5 className="mb-1"><a href="apps-nft-item-details.html" className="link-dark">Evolved Reality</a></h5>
                                    <p className="text-muted mb-0">Video</p>
                                </div>
                                <div className="card-footer border-top border-top-dashed">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1 fs-14">
                                            <i className="ri-price-tag-3-fill text-warning align-bottom me-1"></i> Published <span className="fw-medium">2 days ago</span>
                                        </div>
                                        <h5 className="flex-shrink-0 fs-14 text-primary mb-0">0.5 MIT</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col">
                            <div className="card explore-box card-animate">
                                <div className="bookmark-icon position-absolute top-0 end-0 p-2">
                                    <button type="button" className="btn btn-icon active" data-bs-toggle="button" aria-pressed="true"><i className="mdi mdi-cards-heart fs-16"></i></button>
                                </div>
                                <div className="explore-place-bid-img">
                                    <img src="https://img.themesbrand.com/velzon/images/img-2.gif" alt="" className="card-img-top explore-img"/>
                                    <div className="bg-overlay"></div>
                                    <div className="place-bid-btn">
                                        <a href="#!" className="btn btn-success"><i className="ri-auction-fill align-bottom me-1"></i> Place Bid</a>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <p className="fw-medium mb-0 float-end"><i className="mdi mdi-heart text-danger align-middle"></i> 94.1k </p>
                                    <h5 className="mb-1"><a href="apps-nft-item-details.html" className="link-dark">Trendy Fashion Portraits</a></h5>
                                    <p className="text-muted mb-0">3d Style</p>
                                </div>
                                <div className="card-footer border-top border-top-dashed">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1 fs-14">
                                            <i className="ri-price-tag-3-fill text-warning align-bottom me-1"></i> Published <span className="fw-medium">1 day ago</span>
                                        </div>
                                        <h5 className="flex-shrink-0 fs-14 text-primary mb-0">0.56 MIT</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col">
                            <div className="card explore-box card-animate">
                                <div className="bookmark-icon position-absolute top-0 end-0 p-2">
                                    <button type="button" className="btn btn-icon active" data-bs-toggle="button" aria-pressed="true"><i className="mdi mdi-cards-heart fs-16"></i></button>
                                </div>
                                <div className="explore-place-bid-img">
                                    <img src="https://img.themesbrand.com/velzon/images/img-4.gif" alt="" className="card-img-top explore-img"/>
                                    <div className="bg-overlay"></div>
                                    <div className="place-bid-btn">
                                        <a href="#!" className="btn btn-success"><i className="ri-auction-fill align-bottom me-1"></i> Place Bid</a>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <p className="fw-medium mb-0 float-end"><i className="mdi mdi-heart text-danger align-middle"></i> 15.93k </p>
                                    <h5 className="mb-1"><a href="apps-nft-item-details.html" className="link-dark">Evolved Reality</a></h5>
                                    <p className="text-muted mb-0">Video</p>
                                </div>
                                <div className="card-footer border-top border-top-dashed">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1 fs-14">
                                            <i className="ri-price-tag-3-fill text-warning align-bottom me-1"></i> Published <span className="fw-medium">2 days ago</span>
                                        </div>
                                        <h5 className="flex-shrink-0 fs-14 text-primary mb-0">0.5 MIT</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                        </div>
                        <Footer/>
                    </div>
                </div>
            </div>
        );
    }
}
