import React from 'react'

const LoginLayout = ({children}:any) => {
  return (
    <div className="form-body">
        <div className="website-logo">
            <a href="/">
                <div className="logo">
                    <img className="logo-size" src="images/logo-light.svg" alt=""/>
                </div>
            </a>
        </div>
        <div className="row">
            <div className="img-holder">
                <div className="bg"></div>
                <div className="info-holder">
                    <h1>Connecting Communities</h1>
                    <p style={{lineHeight:'22px'}}>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni, aperiam excepturi minima illum ducimus </p>
                    <img src={`${process.env.PUBLIC_URL}/assets/media/graphic4.svg`} alt=""/>
                </div>
            </div>
            <div className="form-holder">
                <div className="form-content">
                    <div className="form-items">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}


export default LoginLayout;