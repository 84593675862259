import * as React from 'react'
import { Routes, Route } from 'react-router-dom';
import Dashboard from './pages/dashboard';
import Home from './pages/home';
import About from './pages/about';
import Events from './pages/events/events';
import EditEvents from './pages/events/edit-events';
import NFT from './pages/nft';
import Community from './pages/Community';
import AddEvents from './pages/events/add-events';

export default class App extends React.Component {
  render() {
    return (
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/community/create' element={<Community/>} />
        <Route path='/panel/dashboard' element={<Dashboard/>} />
        <Route path='/panel/about' element={<About/>} />
        <Route path='/panel/events' element={<Events/>} />
        <Route path='/panel/events/add' element={<AddEvents/>} />
        <Route path='/panel/nft' element={<NFT/>} />
        <Route path='/panel/edit/events' element={<EditEvents/>} />
      </Routes>
    );
  }
}

