import React from "react";
import { Link } from "react-router-dom";
export default class Sidebar extends React.Component {
	render() {
		return (
			<div id="scrollbar">
				<div className="container-fluid">
					<div id="two-column-menu"></div>
					<ul className="navbar-nav" id="navbar-nav">
						<li className="menu-title">
							<span data-key="t-menu">Menu</span>
						</li>

						<li className="nav-item">
                        <Link className="nav-link menu-link"  to="/panel/dashboard">
								<i className="ri-home-fill"></i>{" "}
								<span data-key="t-widgets">Dashboard</span>
                                </Link>
						</li>
						<li className="nav-item">
                            <Link className="nav-link menu-link"  to="/panel/about">
                                <i className="ri-slideshow-line"></i>
								<span data-key="t-widgets">About</span>
                            </Link>
						</li>
						<li className="nav-item">
                            <Link className="nav-link menu-link"  to="#">
								<i className=" ri-store-2-fill"></i>{" "}
								<span data-key="t-widgets">MiStore</span>
							</Link>
						</li>

						<li className="nav-item">
                        <Link className="nav-link menu-link"  to="#">
                        <i className="ri-stack-overflow-fill"></i>
								<span data-key="t-widgets">MiBundle</span>
                                </Link>
						</li>
						<li className="nav-item">
                        <Link className="nav-link menu-link"  to="/panel/events">
								<i className=" ri-bar-chart-2-fill"></i>{" "}
								<span data-key="t-widgets">MiEvents</span>
                                </Link>
						</li>

						<li className="nav-item">
						<Link className="nav-link menu-link"  to="/panel/nft">
                            <i className="ri-collage-line"></i>
								<span data-key="t-widgets">
									MiNFT
								</span>
							</Link>
						</li>
						<li className="nav-item">
							<a className="nav-link menu-link" href="#">
								<i className=" ri-map-pin-user-fill"></i>{" "}
								<span data-key="t-widgets">Membership</span>
							</a>
						</li>
						<li className="nav-item">
							<a className="nav-link menu-link" href="#">
								<i className=" ri-wallet-fill"></i>{" "}
								<span data-key="t-widgets">Wallet</span>
							</a>
						</li>
						<li className="nav-item">
							<a className="nav-link menu-link" href="#">
								<i className=" ri-exchange-fill"></i>{" "}
								<span data-key="t-widgets">Exchange</span>
							</a>
						</li>
						<li className="nav-item">
							<a className="nav-link menu-link" href="#">
								<i className="ri-team-fill"></i>{" "}
								<span data-key="t-widgets">Team</span>
							</a>
						</li>
						<li className="nav-item">
							<a className="nav-link menu-link" href="#">
								<i className="ri-customer-service-2-fill"></i>{" "}
								<span data-key="t-widgets">Customer help</span>
							</a>
						</li>
					</ul>
				</div>
			</div>
		);
	}
}
