import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDisconnect } from "wagmi";
import Sidebar from "./sidebar";
const Header = () =>{
    const navigate = useNavigate();
    const { disconnectAsync } = useDisconnect();
    const handleLogout = async () => {
        
        await disconnectAsync();
        window.location.href = '/';
        
    }


    return (
        <div>
        <header id="page-topbar">
                <div className="layout-width">
                    <div className="navbar-header">
                        <div className="d-flex">

                            <div className="navbar-brand-box horizontal-logo">


                                <a href="/panel/dashboard" className="logo logo-light">
                                    <span className="logo-sm">
                                    {`${process.env.PUBLIC_URL}/assets/media/avatar-1.jpg`}
                                        <img src={`${process.env.PUBLIC_URL}/assets/media/logo.png`} alt="" height="22"/>
                                    </span>
                                    <span className="logo-lg">
                                        <img src={`${process.env.PUBLIC_URL}/assets/media/logo.png`} alt="" height="22"/>
                                    </span>
                                </a>
                            </div>

                            <button type="button"
                                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                                id="topnav-hamburger-icon">
                                <span className="hamburger-icon">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                            </button>


                        </div>

                        <div className="d-flex align-items-center">


                            <div className="dropdown ms-sm-3 header-item topbar-user">
                                <button type="button" className="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    <span className="d-flex align-items-center">
                                        <img className="rounded-circle header-profile-user"
                                            src={`${process.env.PUBLIC_URL}/assets/media/avatar-1.jpg`} alt="Header Avatar"/>
                                            <span className="text-start ms-xl-2">
                                                <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">MetaChain</span>
                                                <span
                                                    className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">Founder</span>
                                            </span>
                                    </span>
                                </button>
                                <div className="dropdown-menu dropdown-menu-end">
                                    
                                    <h6 className="dropdown-header">Welcome MetaChain!</h6>
                                    <Link className="dropdown-item" to="/panel/about"><i
                                        className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i> <span
                                            className="align-middle">Profile</span></Link>
                                    <a className="dropdown-item" onClick={()=>handleLogout()}>
                                    <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span
                                            className="align-middle" data-key="t-logout">Logout</span>
                                            </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="app-menu navbar-menu">
                
            <div className="navbar-brand-box">
            <Link className="logo logo-dark"  to="/panel/dashboard">
                    <span className="logo-sm">
                        <img src={`${process.env.PUBLIC_URL}/assets/media/logo.png`} alt="" height="22"/>
                    </span>
                    <span className="logo-lg">
                        <img src={`${process.env.PUBLIC_URL}/assets/media/logo.png`} alt="" height="22"/>
                    </span>
                </Link>
                
                <button type="button" className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
                    id="vertical-hover">
                    <i className="ri-record-circle-line"></i>
                </button>
            </div>

            <Sidebar/>

            <div className="sidebar-background"></div>
        </div>
        
        <div className="vertical-overlay"></div>
        </div>
    );   
}
export default Header;
