import axios from 'axios';

export const communityCheck = '/dash/check';
export const cCreate = '/community/create';
export const walletIDToUserID = '/dash/walletinfo';
export const aboutInfo = '/dash/aboutinfo/';
export const eCreate = '/event/create';


const APIRequest = (token:any) => axios.create({
    baseURL: 'https://dash.mitoken.tech/api/wp-json/v1',
    timeout: 100000,
    headers:{
      'Authorization': `Bearer ${token}`,
      'Content-Type':'multipart/form-data'
    }
  });
  
  export default APIRequest;