import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../component/footer";
import Header from "../../component/header";
import Space from "../../component/space";
export default class Events extends React.Component {
	render() {
		return (
			<div id="layout-wrapper">
				<Header />
				<div className="main-content">
					<div className="page-content">
                    <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                    <div className="card rounded-0 bg-soft-success mx-n4 mt-n4 border-top">
                    <div className="px-4">
                        <div className="row">
                            <div className="col-xxl-5 align-self-center">
                                <div className="py-4">
                                    <h4>Publish Events</h4>
                                    <p className="text-success fs-16 mt-3">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                                </div>
                            </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    



                    <div className="row">
                        <div className="col-xxl-4">
                            <div className="card card-secondary">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0">
                                            <img src={`${process.env.PUBLIC_URL}/assets/media/avatar-1.jpg`} alt="" className="avatar-sm rounded-circle"/>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h6 className="fw-medium text-white">Rate your opinion </h6>
                                            <p className="card-text">Fill up the form to create the event.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="text-center">
                                        
                                        <Link to="add" className="link-light">Publish Now <i className="ri-arrow-right-s-line align-middle lh-1"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                        <div className="col-xxl-4">
                            <div className="card card-danger">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0">
                                            <img src={`${process.env.PUBLIC_URL}/assets/media/avatar-1.jpg`} alt="" className="avatar-sm rounded-circle"/>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h6 className="fw-medium text-white">Choose Your Representative </h6>
                                            <p className="card-text">Fill up the form to create the event.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="text-center">
                                        <a href="javascript:void(0);" className="link-light">Publish Now <i className="ri-arrow-right-s-line align-middle lh-1"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                        <div className="col-xxl-4">
                            <div className="card card-dark">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0">
                                            <img src={`${process.env.PUBLIC_URL}/assets/media/avatar-1.jpg`} alt="" className="avatar-sm rounded-circle"/>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h6 className="fw-medium text-white">Vote template</h6>
                                            <p className="card-text">Fill up the form to create the event.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="text-center">
                                        <a href="javascript:void(0);" className="link-light">Publish Now <i className="ri-arrow-right-s-line align-middle lh-1"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                   

                


                  
                </div>
						<Footer />
					</div>
				</div>
			</div>
		);
	}
}
